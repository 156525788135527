import { getInstance, Pedido, PedidoPaService } from "@/services/PedidoService";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

function diffDates(d1: Date, d2: Date) {
  return (d1.getTime() - d2.getTime()) / 1000 / 60 / 60 / 24;
}

export function useCancelar() {
  const toast = useToast();
  const confirm = useConfirm();

  function cancelar(pedido: Pedido) {
    return new Promise<boolean>((resolve) => {
      confirm.require({
        message: "Tem certeza que deseja cancelar o pedido?",
        header: "Confirmação",
        icon: "pi pi-exclamation-triangle",
        async accept() {
          try {
            await getInstance().cancelar(pedido.id);
            toast.add({
              severity: "success",
              summary: "Pedido foi cancelado",
              life: 2_000,
            });
            resolve(true);
          } catch (e) {
            console.log("Falha ao cancelar pedido", e);
            resolve(false);
          }
        },
      });
    });
  }

  return {
    canCancelar(ped: Pedido) {
      return ped.status === "pendente" || ped.status === "solicitado_pa";
    },
    cancelar,
  };
}

export function useCancelarPa() {
  const toast = useToast();
  const confirm = useConfirm();

  function cancelar(pedido: Pedido) {
    return new Promise<boolean>((resolve) => {
      confirm.require({
        message: "Tem certeza que deseja cancelar o pedido?",
        header: "Confirmação",
        icon: "pi pi-exclamation-triangle",
        async accept() {
          try {
            await new PedidoPaService().cancelar(pedido.id);
            toast.add({
              severity: "success",
              summary: "Pedido foi cancelado",
              life: 2_000,
            });
            resolve(true);
          } catch (e) {
            console.log("Falha ao cancelar pedido", e);
            resolve(false);
          }
        },
      });
    });
  }

  return {
    canCancelar(ped: Pedido) {
      return ped.status === "solicitado_pa" &&
        ped.createdAt &&
        diffDates(new Date(), new Date(ped.createdAt)) > 1;
    },
    cancelar,
  };
}
