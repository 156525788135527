<template>
  <div class="p-grid card">
    <p-toolbar class="p-mb-4 p-col-12">
      <template #left>
        <h5 class="p-m-0">Meus Pedidos</h5>
      </template>

      <template #right>
        <span class="p-d-inline-flex p-mr-2">
          <!-- <p-inputtext
            v-model="dataParams.filter"
            @keypress.enter="find"
            placeholder="Pesquisar..."
          /> -->
          <p-button icon="pi pi-search" @click="find" />
        </span>
      </template>
    </p-toolbar>

    <p-datatable
      ref="dt"
      :value="data"
      v-model:selection="selectedItens"
      dataKey="id"
      :loading="loading"
      :first="0"
      :lazy="true"
      :paginator="true"
      :rows="dataParams.rows"
      :totalRecords="dataParams.totalRecords"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="{first} - {last} de {totalRecords} registros"
      @page="onPage"
      @sort="onSort"
      responsiveLayout="stack"
      stripedRows
      class="p-col-12"
    >
      <template #empty>
        <div class="p-text-center p-mt-5 p-mb-5">
          Nenhum registro encontrado!
        </div>
      </template>

      <p-column field="e.id" header="Nº" :sortable="true">
        <template #body="{ data }">
          {{ data.id }}
        </template>
      </p-column>
      <p-column field="e.created_at" header="Data" :sortable="true">
        <template #body="{ data }">
          {{ new Date(data.createdAt).toLocaleString() }}
        </template>
      </p-column>
      <p-column field="e.total" header="Total" :sortable="true">
        <template #body="{ data }">
          {{ $formatCurrency(data.total) }}
        </template>
      </p-column>
      <p-column header="Status">
        <template #body="slotProps">
          <Status :value="slotProps.data.status" />
        </template>
      </p-column>
      <p-column bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <p-button
            v-if="canCancelar(slotProps.data)"
            class="p-button-help p-mr-1 p-mt-1"
            style="background: #ec1de0"
            @click="cancelar(slotProps.data)"
            label="Cancelar"
          />
          <p-button
            v-if="slotProps.data.status === 'pendente'"
            label="Pagar"
            @click="pagar(slotProps.data)"
            class="p-mr-1 p-mt-1"
          />
          <p-button
            label="Detalhes"
            class="p-button-secondary p-mt-1"
            @click="detalhes(slotProps.data)"
          />
        </template>
      </p-column>
    </p-datatable>
  </div>
</template>

<script lang="ts">
import { getInstance, Pedido, PedidoItem } from "@/services/PedidoService";
import { DataTableControl } from "@/services/Services";
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "@/store";
import Status from "./Status.vue";
import { useCancelar } from "@/composables/pedido";

export default defineComponent({
  components: { Status },
  data() {
    return {
      cadastroVisible: false,
      deleteItemDialog: false,
      item: {},
      selectedItens: null,
      submitted: false,
    };
  },
  setup() {
    const data = ref<Pedido[]>([]);
    const loading = ref(false);
    const dataParams = reactive<DataTableControl>({
      rows: 10,
      page: 0,
      sortField: "e.id",
      sortOrder: -1,
      totalRecords: 0,
      filter: "",
    });

    const store = useStore();

    async function find() {
      loading.value = true;
      try {
        const result = await getInstance().find({
          // q: dataParams.filter,
          userId: store.state.user?.id,
          first: dataParams.rows,
          page: dataParams.page + 1,
          sortField: dataParams.sortField,
          sortOrder: dataParams.sortOrder > 0 ? "ASC" : "DESC",
        });
        data.value = result.data;
        dataParams.totalRecords = result.paginatorInfo.total;
      } finally {
        loading.value = false;
      }
    }

    const { canCancelar, cancelar: doCancelar } = useCancelar();

    return {
      canCancelar,
      async cancelar(p: Pedido) {
        if (await doCancelar(p)) {
          find();
        }
      },
      loading,
      dataParams,
      find,
      data,
    };
  },
  mounted() {
    this.find();
  },
  methods: {
    onPage(evt: any) {
      this.dataParams.rows = evt.rows;
      this.dataParams.page = evt.page;
      this.find();
    },
    onSort(evt: any) {
      this.dataParams.sortField = evt.sortField;
      this.dataParams.sortOrder = evt.sortOrder;
      this.find();
    },
    detalhes(pedido: Pedido) {
      this.$router.push({
        name: "pedido-detalhes",
        params: {
          id: pedido.id,
        },
      });
    },
    pagar(pedido: Pedido) {
      this.$router.push({
        name: "pedido-checkout",
        params: {
          id: pedido.id,
        },
      });
    },
  },
});
</script>
